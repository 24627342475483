// import { EventBus } from '@/main'
import store from '@/store'
// import { wsurl } from '@/utils/ajax'
let jumpTik
let reLink
let notNeedLink = true
let jumpNoNum = 0
let ljUrls = ''
export default function WebSocketInit(ljUrl) {
  ljUrls = ljUrl
  if (notNeedLink == true) {
    notNeedLink = false
    let wst = ''
    if (document.location.protocol === 'https:') {
      wst = 'wss://'
    } else {
      wst = 'ws://'
    }
    // const url = 'ws://rent.tzgcs360.com' + ljUrl
    const url = wst + 'suzkj.com' + ljUrl
    const ws = new WebSocket(url)
    ws.onopen = function (params) {
      console.log("客户端连接成功");
      //   // 向服务器发送消息
      // ws.send("hello");
    };

    ws.onclose = () => {
      console.log('WebSocket断开连接')
      notNeedLink = true
      let duankai = window.localStorage.getItem("duankai");
      if (duankai == 1) {
        clearInterval(reLink);
      }
    }

    ws.onerror = err => {
      notNeedLink = true
      if (jumpTik) clearInterval(jumpTik)
      let duankai = window.localStorage.getItem("duankai");
      if (duankai != 1) {
        clearInterval(reLink);
        reLinkTask()
      }
      if (duankai == 1) {
        clearInterval(reLink);
      }
    }

    ws.onmessage = res => {
      const data = JSON.parse(res.data)
      window.dispatchEvent(new CustomEvent('onmessageWS', {
        detail: {
          data: data
        }
      }))
      // EventBus.$emit('updatelist', data)
    }
    window.ws1 = ws
    // store.commit('SET_WS', ws)
  }
}

function reLinkTask() {
  // console.log(notNeedLink);
  // if (!notNeedLink ) {
  reLink = setInterval(() => {
    console.log('重新连接WebSocket')
    WebSocketInit(ljUrls)
  }, 5000)
  // }
}